import React from "react";
import { classNames } from "primereact/utils";
import AppMenu from "../app/AppMenu";
import useAuth from "../hooks/useAuth";
import useCurrentUser from "../hooks/useCurrentUser";
import useDepartmentWorkflows from "../hooks/useDepartmentWorkflows";
import { Image } from "primereact/image";
import { Link } from "react-router-dom";

const AppTopbar = (props) => {
  const { signOutUser, getToken } = useAuth();
  const accessToken = getToken();
  const { userData } = useCurrentUser(accessToken);
  const { workflowList: departmentWorkflows } =
    useDepartmentWorkflows(accessToken);

  let topbarMenuClassName = classNames("layout-profile-menu fadeInDown ", {
    "layout-profile-menu-active": props.topbarUserMenuActive,
  });
  let menuButtonClassName = classNames("layout-menubutton ", {
    "layout-menubutton-active": props.menuActive,
  });

  // const theme = {
  //   light: {
  //     layout: {
  //       background: "white",
  //       wrapper: {
  //         secondaryFontColor: "red",
  //       },
  //     },
  //     header: {
  //       badgeTextColor: "white",
  //     },
  //     notificationItem: {
  //       buttons: {
  //         primary: {
  //           backGroundColor: "#1e88e5",
  //           fontFamily:
  //             "Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
  //         },
  //       },
  //       seen: {
  //         background: "#e6f0ff",
  //         fontColor: "#6f6f6f",
  //         timeMarkFontColor: "#3c3c3c",
  //       },
  //       unseen: {
  //         background: "#b2d6ff",
  //         notificationItemBeforeBrandColor: "#1e88e5",
  //       },
  //     },
  //     loaderColor: "#1e88e5",
  //   },
  //   common: {
  //     fontFamily:
  //       "Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
  //   },
  // };

  return (
    <>
      <div className="layout-topbar">
        <button
          type="button"
          className={menuButtonClassName}
          onClick={props.onMenuButtonClick}
        >
          <div className="layout-menubutton-icon" />
        </button>

        <div className="layout-topbar-grid">
          <div className="layout-topbar-grid-column layout-topbar-grid-column-fixed flex justify-content-center xl:max-w-15rem">
            <Image
              src="/assets/layout/images/ddb-logo.png"
              alt="Image"
              width="55"
              height="55"
              className="mb-1"
            />
            <span className="text-white text-5xl font-light flex align-self-center ml-2">
              DTPMS&nbsp;
              <span className="text-xl flex align-self-center">v01</span>
            </span>
          </div>

          <div className="layout-topbar-grid-column">
            <AppMenu
              horizontal={props.horizontal}
              menuHoverActive={props.menuHoverActive}
              isMobile={props.isMobile}
              onMenuItemClick={props.onMenuItemClick}
              onRootMenuItemClick={props.onRootMenuItemClick}
              onSidebarClick={props.onSidebarClick}
            />
          </div>

          <div className="layout-topbar-grid-column layout-topbar-grid-column-fixed">
            {userData && (
              <>
                <button
                  type="button"
                  className="p-link profile-menu-button"
                  onClick={props.onTopbarUserMenuButtonClick}
                >
                  <i className="topbar-icon pi pi-user text-white font-light text-xl m-0 mr-2"></i>
                  <span className="text-white">
                    {userData && userData.username}
                  </span>
                </button>
                <ul
                  className={topbarMenuClassName}
                  onClick={props.onTopbarUserMenuClick}
                >
                  <li role="menuitem">
                    <div className="flex justify-content-between">
                      <div className="flex justify-content-start mt-2 ml-2">
                        You are logged in as{" "}
                        {userData &&
                          userData.lastName + ", " + userData.firstName}
                        <br />
                        You're division/unit is{" "}
                        {departmentWorkflows &&
                          departmentWorkflows?.length > 0 && (
                            <>{departmentWorkflows[0].department?.name}</>
                          )}
                      </div>
                      <div className="flex justify-content-end">
                        <button
                          type="button"
                          className="p-link p-ripple mt-5"
                          onClick={signOutUser}
                        >
                          <i className="pi pi-times"></i>
                          <span>Logout</span>
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </>
            )}
            {!userData && (
              <>
                <Link
                  to={"/login"}
                  className="p-custom-tooltip"
                  data-pr-tooltip="Get Help"
                  data-pr-position="top"
                  data-pr-at="center top-10"
                >
                  <i className="pi pi-fw text-white pi-lock text-xl" />
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AppTopbar;
