import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useContext,
  useRef,
} from "react";
import { Button } from "primereact/button";
import DocumentViewer from "../components/DocumentViewer";
import TaskDecision from "../components/TaskDecision";
import FinalizeTask from "../components/FinalizeTask";
import { useParams, useNavigate } from "react-router-dom";
import { queryGraphQL } from "../services/apiGraphQL";
import { GET_TASK_DETAILS } from "../graphQL/tasks";
import useAuth from "../hooks/useAuth";
import {
  updateTaskStatus,
  updateTaskData,
  attachTaskFile,
} from "../services/apiTasks";
import { ConfirmDialog } from "primereact/confirmdialog";

import FileList from "../components/FileList";
import { Splitter, SplitterPanel } from "primereact/splitter";
import FolderNavigator from "../components/FolderNavigator";
import SelectedFolder from "../components/SelectedFolder";
import ChatPanel from "../components/ChatPanel";
import { Toast } from "primereact/toast";
import { dialogMessageList, actionSuccess } from "../common/dialogMessages";
import { getBinaryFile } from "../services/apiFiles";
import {
  formatDate,
  formatFilename,
  getFilenameDate,
} from "../common/utilities";
import { ActivityDetailsContext } from "../contexts/ActivityDetailsContext";
import { RefreshFolderContentContext } from "../contexts/RefreshFolderContentContext";
import { SignatureContext } from "../contexts/SignatureContext";
import useTaskList from "../hooks/useTaskList";
import useActivityList from "../hooks/useActivityList";
import useCollaborationList from "../hooks/useCollaborationList";
import useFolderList from "../hooks/useFolderList";
import TaskRoute from "../components/TaskRoute";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Sidebar } from "primereact/sidebar";
import TaskBulkUploader from "../components/TaskBulkUploader";
import { Link } from "react-router-dom";
import { taskAssigneeGraphQlFilterString } from "../common/utilities";
import useAssignmentCounts from "../hooks/useAssignmentCounts";

export default function TaskViewer(props) {
  const { userSignatures, setUserSignatures } = useContext(SignatureContext);
  const { setShouldRefreshContents } = useContext(ActivityDetailsContext);
  const { setShouldRefreshContents: setRefreshFolderContent } = useContext(
    RefreshFolderContentContext
  );
  const params = useParams();
  const navigate = useNavigate();

  const { getToken, userId } = useAuth();
  const accessToken = getToken();
  const [visibleTop, setVisibleTop] = useState(false);
  const [visibleTopSubmitted, setVisibleTopSubmitted] = useState(false);
  const [visible, setVisible] = useState(false);
  const [approveDecision, setApproveDecision] = useState(false);
  const [rejectDecision, setRejectDecision] = useState(false);
  const [rejectReviseDecision, setRejectReviseDecision] = useState(false);
  const [cancelDecision, setCancelDecision] = useState(false);
  const [visibleRight, setVisibleRight] = useState(false);
  const [binaryFile, setBinaryFile] = useState();
  const [taskDetails, setTaskDetails] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileInReview, setFileInReview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [route1Selection, setRoute1Selection] = useState(false);
  const [route2Selection, setRoute2Selection] = useState(false);
  const [route3Selection, setRoute3Selection] = useState(false);
  const [route4Selection, setRoute4Selection] = useState(false);
  const [userPrivateFolder, setUserPrivateFolder] = useState();
  const [taskFiles, setTaskFiles] = useState([]);
  const toast = useRef(null);
  const { folderList } = useFolderList(accessToken);
  const { mutateTaskList } = useTaskList(accessToken);
  const { mutateActivityList } = useActivityList(accessToken);
  const { mutateCollaborationList } = useCollaborationList(
    accessToken,
    taskAssigneeGraphQlFilterString(userId)
  );
  const { mutateAssignmentCounts } = useAssignmentCounts(
    accessToken,
    taskAssigneeGraphQlFilterString(userId)
  );

  const loadFile = async (file) => {
    getBinaryFile(file.id, accessToken)
      .then((buffer) => {
        setBinaryFile(buffer.data);
      })
      .catch((e) => {
        throw e;
      });
  };

  useEffect(() => {
    const privateFolder = folderList?.find((folder) => {
      if (folder.folderType === "PRIVATE" && folder.name === "PRIVATE") {
        return folder;
      } else {
        return null;
      }
    });
    setUserPrivateFolder(privateFolder);
  }, [folderList]);

  useLayoutEffect(() => {
    queryGraphQL(
      GET_TASK_DETAILS,
      { taskId: parseInt(params.taskId) },
      accessToken
    )
      .then((response) => {
        if (response.tasks.length > 0) {
          if (response.tasks[0].status === "CANCELLED") {
            navigate("/error", { replace: true });
          }

          const taskData = response.tasks[0];

          const workflow = JSON.parse(taskData.activity.workflow);

          const edges = workflow.edges.filter(
            (x) => x.source === taskData.nodeId
          );

          const targetRoutes = edges
            .map((edge) => {
              const targetNode = workflow.nodes.find(
                (x) => x.id === edge.target
              );

              if (targetNode.type !== "notificationNode") {
                const targetRoute = {
                  label: targetNode.data?.customNodeName
                    ? targetNode.data?.customNodeName
                    : targetNode.data.label,
                  taskAssignee: targetNode.data?.taskAssignee,
                  targetUsers: targetNode.data?.targetUsers,
                  type: targetNode.type,
                  targetId: targetNode.id,
                  sourceId: edge.source,
                  sourceHandle: edge.sourceHandle,
                };
                return targetRoute;
              } else {
                return false;
              }
            })
            .filter(Boolean);

          const node = workflow.nodes.find((x) => x.id === taskData.nodeId);

          const nodeSettings = node.data;
          delete nodeSettings["allowEdit"];
          delete nodeSettings["label"];

          const taskPayload = JSON.parse(taskData.data);

          const updatedTaskDetails = {
            ...taskDetails,
            taskId: taskData.id,
            taskName: taskData.name,
            customNodeName: taskData.customNodeName,
            status: taskData.status,
            initiatorUserId: taskData.initiatorUserId,
            assigneeId: taskData.assigneeId,
            dateRequested: taskData.dateRequested,
            startTime: taskData.startTime,
            endTime: taskData.endTime,
            activityId: taskData.activity.id,
            activityFolderId: taskData.activity.folderId,
            activityName: taskData.activity.name,
            templateId: taskData.activity.templateId,
            taskType: node.type,
            settings: nodeSettings,
            workflow: workflow,
            data: taskPayload,
            previousTaskId: taskData.previousTaskId,
            files: taskData.files,
            targetNodeRoutes: targetRoutes,
            mainFiles: taskData.activity.mainFiles,
          };

          setTaskDetails(updatedTaskDetails);

          if (
            node.type === "basicNode" ||
            node.type === "basicApprovalNode" ||
            node.type === "finalApprovalNode" ||
            node.type === "reviewNode1" ||
            node.type === "reviewNode" ||
            node.type === "routerNode2" ||
            node.type === "routerNode3" ||
            node.type === "routerNode4"
          ) {
            if (
              taskData.status !== "COMPLETED" &&
              taskData.status !== "REJECTED" &&
              taskData.status !== "REJECT_AND_REVISE" &&
              taskData.status !== "CANCELLED"
            ) {
              if (taskData.previousTaskId) {
                // handle error on first task of activity (e.g. there are no previous tasks)
                // get previous task details
                return queryGraphQL(
                  GET_TASK_DETAILS,
                  { taskId: parseInt(taskData.previousTaskId) },

                  accessToken
                )
                  .then((response) => {
                    // get taskPayload
                    const taskPayload = JSON.parse(response.tasks[0].data);
                    setUserSignatures(taskPayload.userSignatures);

                    const taskFiles = response.tasks[0].files;
                    setTaskFiles(taskFiles);
                  })
                  .catch((e) => {
                    console.log("e: ", e);
                  });
              }
            } else {
              // get signatures for current closed task
              const taskPayload = JSON.parse(taskData.data);
              setUserSignatures(taskPayload.userSignatures);
            }
          }
        }
      })
      .catch((e) => {
        console.log("e: ", e);

        console.log(
          "Node data incomplete. Double-check task and workflow template details."
        );
      })
      .finally(() => {});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleApproveDecision = (e) => {
    setApproveDecision(e.value);
    setRejectDecision(false);
    setRejectReviseDecision(false);
    setCancelDecision(false);
  };

  const handleRejectDecision = (e) => {
    setApproveDecision(false);
    setRejectDecision(e.value);
    setRejectReviseDecision(false);
    setCancelDecision(false);
  };

  const handleRevisionDecision = (e) => {
    setApproveDecision(false);
    setRejectDecision(false);
    setRejectReviseDecision(e.value);
    setCancelDecision(false);
  };

  const handleCancelDecision = (e) => {
    setApproveDecision(false);
    setRejectDecision(false);
    setRejectReviseDecision(false);
    setCancelDecision(e.value);
  };

  const handleRoute1Selection = (e) => {
    setRoute1Selection(e.value);
    setRoute2Selection(false);
    setRoute3Selection(false);
    setRoute4Selection(false);
  };

  const handleRoute2Selection = (e) => {
    setRoute1Selection(false);
    setRoute2Selection(e.value);
    setRoute3Selection(false);
    setRoute4Selection(false);
  };

  const handleRoute3Selection = (e) => {
    setRoute1Selection(false);
    setRoute2Selection(false);
    setRoute3Selection(e.value);
    setRoute4Selection(false);
  };

  const handleRoute4Selection = (e) => {
    setRoute1Selection(false);
    setRoute2Selection(false);
    setRoute3Selection(false);
    setRoute4Selection(e.value);
  };

  const handleFileSelect = (data) => {
    setSelectedFile(data);
  };

  const handleFileRemove = (data) => {
    const index = selectedFile
      .map(function (item) {
        return item.id;
      })
      .indexOf(data);

    selectedFile.splice(index, 1);

    setSelectedFile(selectedFile);

    setRefreshFolderContent(true);
  };

  const handleTaskSubmission = (e) => {
    setLoading(true);
    let data = { userSignatures: userSignatures };
    let taskStatus = "IN_PROGRESS";
    let selectedFileIds = [];
    let chosenNode = null;

    if (taskDetails.taskType === "basicNode") {
      let userSelectedFiles = selectedFile.map(function (item) {
        return item.id;
      });

      let carryOverFiles = taskFiles.map((file) => {
        return file.id;
      });
      selectedFileIds = carryOverFiles.concat(userSelectedFiles);
      taskStatus = "COMPLETED";
    } else if (
      taskDetails.taskType === "basicApprovalNode" ||
      taskDetails.taskType === "finalApprovalNode" ||
      taskDetails.taskType === "reviewNode1" ||
      taskDetails.taskType === "reviewNode"
    ) {
      taskStatus = approveDecision
        ? "COMPLETED"
        : rejectDecision
        ? "REJECTED"
        : rejectReviseDecision
        ? "REJECT_AND_REVISE"
        : cancelDecision
        ? "CANCELLED"
        : "IN_PROGRESS";

      chosenNode = approveDecision
        ? "a"
        : rejectDecision
        ? "b"
        : rejectReviseDecision
        ? "c"
        : cancelDecision
        ? "d"
        : null;

      selectedFileIds = taskFiles.map((file) => {
        return file.id;
      });
      // handle files from this node type
    } else if (
      taskDetails.taskType === "routerNode2" ||
      taskDetails.taskType === "routerNode3" ||
      taskDetails.taskType === "routerNode4"
    ) {
      selectedFileIds = taskFiles.map((file) => {
        return file.id;
      });
      taskStatus = "COMPLETED";
      chosenNode = route1Selection
        ? "e"
        : route2Selection
        ? "f"
        : route3Selection
        ? "g"
        : route4Selection
        ? "h"
        : "e";
    }

    attachTaskFile(
      parseInt(params.taskId),
      { fileIds: selectedFileIds },
      accessToken
    ).then((attachmentResponse) => {
      const taskDataPayload = JSON.parse(attachmentResponse.data);
      taskDataPayload["userSignatures"] = userSignatures;

      updateTaskData(
        parseInt(attachmentResponse.id),
        { taskData: JSON.stringify(data) },
        accessToken
      )
        .then((response) => {
          let statusPayload = { status: taskStatus, chosenNode: chosenNode };

          updateTaskStatus(parseInt(response.id), statusPayload, accessToken)
            .then((result) => {
              if (
                result.status === "COMPLETED" ||
                result.status === "REJECTED" ||
                result.status === "REJECT_AND_REVISE" ||
                result.status === "CANCELLED"
              ) {
                setShouldRefreshContents(true);

                mutateActivityList();
                mutateTaskList();
                mutateCollaborationList();
                mutateAssignmentCounts();

                toast.current.show(
                  actionSuccess(dialogMessageList.TaskCompletedSuccessful)
                );

                setTimeout(() => {
                  navigate("/tasks/" + taskDetails.activityId, {
                    replace: false,
                  });
                }, 1200);
              }
            })
            .catch((e) => {
              console.log("e: ", e);
              setLoading(false);
            });
        })
        .catch((e) => {
          console.log("e: ", e);
          setLoading(false);
        });
    });
  };

  const onHide = () => {
    setVisibleRight(false);
  };

  const bodyTemplate = (rowData) => {
    return (
      <>
        <div className="flex w-full justify-content-between">
          <Button
            label={formatFilename(rowData.name)}
            className="p-button-link"
            onClick={() => {
              loadFile(rowData);
              setFileInReview(rowData);

              setTimeout(() => {
                setVisibleTop(true);
              }, 100);
            }}
          />
          <p>{getFilenameDate(rowData.name)}</p>
        </div>
      </>
    );
  };

  const submittedBodyTemplate = (rowData) => {
    return (
      <>
        <div className="flex w-full justify-content-between">
          <Button
            label={formatFilename(rowData.name)}
            className="p-button-link"
            onClick={() => {
              loadFile(rowData);
              setFileInReview(rowData);

              setTimeout(() => {
                setVisibleTopSubmitted(true);
              }, 100);
            }}
          />
          <p>{getFilenameDate(rowData.name)}</p>
        </div>
      </>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      {taskDetails && (
        <>
          <div className="grid">
            <div className="col-12">
              <div className="card">
                <span className="flex justify-content-between">
                  <h3 className="m-3 mb-4">
                    {taskDetails.customNodeName &&
                    taskDetails.customNodeName !== "< Node Name >"
                      ? taskDetails.customNodeName
                      : taskDetails.taskName}
                  </h3>
                  <div className="flex justify-content-end align-items-center">
                    {taskDetails.status !== "COMPLETED" &&
                      taskDetails.status !== "REJECT_AND_REVISE" && (
                        <>
                          {taskDetails.taskType !== "basicNode" && (
                            <>
                              <span className="flex justify-content-end m-3">
                                <Link to={"/tasks/" + taskDetails.activityId}>
                                  Go to Activity Dashboard
                                </Link>
                              </span>
                              <TaskBulkUploader
                                folderId={userPrivateFolder?.id}
                                taskId={taskDetails.taskId}
                                activityId={taskDetails.activityId}
                                forWetSignatureDocuments={
                                  taskDetails.taskType === "finalApprovalNode"
                                }
                              />
                            </>
                          )}
                        </>
                      )}
                  </div>
                </span>
                {taskDetails.status !== "COMPLETED" &&
                  taskDetails.status !== "REJECT_AND_REVISE" && (
                    <>
                      {taskDetails.taskType === "basicNode" && (
                        <>
                          <SelectedFolder readOnlyMode={true} />
                          <Splitter className="mb-5">
                            <SplitterPanel
                              className="overflow-auto flex align-items-top justify-content-start"
                              size={20}
                              minSize={20}
                            >
                              <Splitter layout="vertical">
                                <SplitterPanel
                                  className="overflow-auto flex align-items-top justify-content-start"
                                  size={35}
                                  minSize={35}
                                >
                                  <FolderNavigator readOnlyMode={false} />
                                </SplitterPanel>
                                <SplitterPanel className="overflow-auto flex align-items-top justify-content-start">
                                  <>
                                    {taskFiles && taskFiles.length > 0 && (
                                      <DataTable
                                        value={taskFiles}
                                        datakey="id"
                                        size="small"
                                        sortMode="single"
                                        removableSort
                                      >
                                        <Column
                                          field="name"
                                          header="Task Document(s)"
                                          sortable
                                          sortField="name"
                                          body={submittedBodyTemplate}
                                        />
                                      </DataTable>
                                    )}
                                    <Sidebar
                                      fullScreen
                                      visible={visibleTopSubmitted}
                                      className="bg-white-100"
                                      onHide={() =>
                                        setVisibleTopSubmitted(false)
                                      }
                                      blockScroll
                                    >
                                      <DocumentViewer
                                        file={binaryFile}
                                        fileName={fileInReview?.name}
                                        taskId={taskDetails.taskId}
                                        isCurrentUserTaskOwner={
                                          userId === taskDetails.assigneeId
                                        }
                                      />
                                    </Sidebar>
                                  </>
                                </SplitterPanel>
                              </Splitter>
                            </SplitterPanel>
                            <SplitterPanel
                              className="flex align-items-top"
                              size={75}
                              minSize={60}
                            >
                              <FileList
                                readOnlyMode={true}
                                showSelectedFiles={true}
                                selectedFile={selectedFile}
                                onFileSelect={handleFileSelect}
                                onFileRemove={handleFileRemove}
                              />
                            </SplitterPanel>
                          </Splitter>
                        </>
                      )}

                      {(taskDetails.taskType === "basicApprovalNode" ||
                        taskDetails.taskType === "finalApprovalNode" ||
                        taskDetails.taskType === "reviewNode1" ||
                        taskDetails.taskType === "reviewNode" ||
                        taskDetails.taskType === "routerNode2" ||
                        taskDetails.taskType === "routerNode3" ||
                        taskDetails.taskType === "routerNode4") && (
                        <>
                          {taskFiles && taskFiles.length > 0 && (
                            <DataTable
                              value={taskFiles}
                              datakey="id"
                              size="small"
                              sortMode="single"
                              removableSort
                            >
                              <Column
                                field="name"
                                header="Submitted Document(s)"
                                sortable
                                sortField="name"
                                body={bodyTemplate}
                              />
                            </DataTable>
                          )}
                          {taskDetails && taskDetails.files?.length > 0 && (
                            <DataTable
                              value={taskDetails.files}
                              datakey="id"
                              size="small"
                              sortMode="single"
                              removableSort
                            >
                              <Column
                                field="name"
                                header="Added Document(s)"
                                sortable
                                sortField="name"
                                body={bodyTemplate}
                              />
                            </DataTable>
                          )}
                          {taskDetails && taskDetails.mainFiles?.length > 0 && (
                            <DataTable
                              value={taskDetails.mainFiles}
                              datakey="id"
                              size="small"
                              sortMode="single"
                              removableSort
                            >
                              <Column
                                field="name"
                                header="Wet Signature Document(s)"
                                sortable
                                sortField="name"
                                body={bodyTemplate}
                              />
                            </DataTable>
                          )}
                          <Sidebar
                            fullScreen
                            visible={visibleTop}
                            className="bg-white-100"
                            onHide={() => setVisibleTop(false)}
                            blockScroll
                          >
                            <DocumentViewer
                              file={binaryFile}
                              fileName={fileInReview?.name}
                              taskId={taskDetails.taskId}
                              isCurrentUserTaskOwner={
                                userId === taskDetails.assigneeId
                              }
                              taskState="OPEN"
                              activityId={taskDetails.activityId}
                            />
                          </Sidebar>
                        </>
                      )}

                      {userId === taskDetails.assigneeId && (
                        <>
                          {(taskDetails.taskType === "basicApprovalNode" ||
                            taskDetails.taskType === "finalApprovalNode" ||
                            taskDetails.taskType === "reviewNode" ||
                            taskDetails.taskType === "reviewNode1") && (
                            <TaskDecision
                              showRejectButton={
                                taskDetails.taskType !== "reviewNode" &&
                                taskDetails.taskType !== "reviewNode1"
                              }
                              showReviseButton={
                                taskDetails.taskType !== "reviewNode1"
                              }
                              showCancelButton={
                                taskDetails.taskType === "finalApprovalNode"
                              }
                              approveLabel={
                                taskDetails.taskType === "reviewNode"
                                  ? "REVIEW COMPLETE"
                                  : taskDetails.taskType === "reviewNode1"
                                  ? "COMPLETE"
                                  : "APPROVE"
                              }
                              approveDecision={approveDecision}
                              rejectDecision={rejectDecision}
                              rejectReviseDecision={rejectReviseDecision}
                              cancelDecision={cancelDecision}
                              onApproveDecision={handleApproveDecision}
                              onRejectDecision={handleRejectDecision}
                              onRevisionDecision={handleRevisionDecision}
                              onCancelDecision={handleCancelDecision}
                            />
                          )}
                          {(taskDetails.taskType === "routerNode2" ||
                            taskDetails.taskType === "routerNode3" ||
                            taskDetails.taskType === "routerNode4") && (
                            <>
                              <TaskRoute
                                targetRoutes={taskDetails.targetNodeRoutes}
                                route1Selection={route1Selection}
                                route2Selection={route2Selection}
                                route3Selection={route3Selection}
                                route4Selection={route4Selection}
                                onRoute1Selection={handleRoute1Selection}
                                onRoute2Selection={handleRoute2Selection}
                                onRoute3Selection={handleRoute3Selection}
                                onRoute4Selection={handleRoute4Selection}
                              />
                            </>
                          )}
                        </>
                      )}

                      <ConfirmDialog
                        visible={visible}
                        onHide={() => setVisible(false)}
                        message="Have you uploaded all files needed, including wet signature documents?"
                        header={
                          taskDetails.customNodeName &&
                          taskDetails.customNodeName !== "< Node Name >"
                            ? taskDetails.customNodeName
                            : taskDetails.taskName
                        }
                        icon="pi pi-exclamation-triangle"
                        //position="bottom"
                        accept={handleTaskSubmission}
                      />

                      <FinalizeTask
                        onTaskSubmission={
                          taskDetails.taskType === "finalApprovalNode"
                            ? () => setVisible(true)
                            : handleTaskSubmission
                        }
                        isLoading={loading}
                        isCurrentUserTaskOwner={
                          userId === taskDetails.assigneeId
                        }
                        hasSelection={
                          route1Selection ||
                          route2Selection ||
                          route3Selection ||
                          route4Selection ||
                          approveDecision ||
                          rejectDecision ||
                          rejectReviseDecision ||
                          cancelDecision ||
                          selectedFile?.length > 0
                        }
                      />
                    </>
                  )}

                {(taskDetails.status === "COMPLETED" ||
                  taskDetails.status === "REJECT_AND_REVISE") && (
                  <>
                    <span className="flex justify-content-between">
                      <div className="text-800 m-3 mb-5 text-xl">
                        Task Completed!
                        <span className="text-500 m-3 mb-5 text-lg">
                          No further actions needed.
                        </span>
                      </div>
                      <span className="flex justify-content-end">
                        <Link to={"/tasks/" + taskDetails.activityId}>
                          Go to Activity Dashboard
                        </Link>
                      </span>
                    </span>

                    <hr className="mx-3 mb-3" />
                    <div className="flex justify-content-between mx-6">
                      <div>Task Assignee</div>
                      <div>{taskDetails.settings.taskAssignee.name}</div>
                    </div>
                    <hr className="mx-3 mb-3" />
                    <div className="flex justify-content-between mx-6">
                      <div>Start Time</div>
                      <div>{formatDate(taskDetails.startTime)}</div>
                    </div>
                    <hr className="mx-3 mb-3" />
                    <div className="flex justify-content-between mx-6">
                      <div>End Time</div>
                      <div>{formatDate(taskDetails.endTime)}</div>
                    </div>
                    <hr className="mx-3 mb-3" />
                    {(taskDetails.taskType === "basicNode" ||
                      taskDetails.taskType === "basicApprovalNode" ||
                      taskDetails.taskType === "finalApprovalNode" ||
                      taskDetails.taskType === "reviewNode" ||
                      taskDetails.taskType === "reviewNode1" ||
                      taskDetails.taskType === "routerNode2" ||
                      taskDetails.taskType === "routerNode3" ||
                      taskDetails.taskType === "routerNode4") && (
                      <>
                        {/* {taskFiles && taskFiles.length > 0 && (
                          <DataTable
                            value={taskFiles}
                            datakey="id"
                            size="small"
                            sortMode="single"
                            removableSort
                          >
                            <Column
                              field="name"
                              header={
                                taskDetails.taskType === "basicNode"
                                  ? "Selected Document(s)"
                                  : taskDetails.taskType ===
                                      "basicApprovalNode" ||
                                    taskDetails.taskType ===
                                      "finalApprovalNode" ||
                                    taskDetails.taskType === "reviewNode" ||
                                    taskDetails.taskType === "reviewNode1"
                                  ? "Approved Document(s)"
                                  : ""
                              }
                              sortable
                              sortField="name"
                              body={submittedBodyTemplate}
                            />
                          </DataTable>
                        )} */}
                        {taskDetails && taskDetails.files?.length > 0 && (
                          <DataTable
                            value={taskDetails.files}
                            datakey="id"
                            size="small"
                            sortMode="single"
                            removableSort
                          >
                            <Column
                              field="name"
                              header="Task Document(s)"
                              sortable
                              sortField="name"
                              body={submittedBodyTemplate}
                            />
                          </DataTable>
                        )}
                        {taskDetails && taskDetails.mainFiles?.length > 0 && (
                          <DataTable
                            value={taskDetails.mainFiles}
                            datakey="id"
                            size="small"
                            sortMode="single"
                            removableSort
                          >
                            <Column
                              field="name"
                              header="Wet Signature Document(s)"
                              sortable
                              sortField="name"
                              body={submittedBodyTemplate}
                            />
                          </DataTable>
                        )}
                        <Sidebar
                          fullScreen
                          visible={visibleTopSubmitted}
                          className="bg-white-100"
                          onHide={() => setVisibleTopSubmitted(false)}
                          blockScroll
                        >
                          <DocumentViewer
                            file={binaryFile}
                            fileName={fileInReview?.name}
                            taskId={taskDetails.taskId}
                            isCurrentUserTaskOwner={
                              userId === taskDetails.assigneeId
                            }
                            taskState="CLOSED"
                            activityId={taskDetails.activityId}
                          />
                        </Sidebar>
                      </>
                    )}
                    {taskDetails.taskType === "notificationNode" && <></>}
                  </>
                )}
              </div>
            </div>
          </div>
          <ChatPanel
            visible={visibleRight}
            handleHide={onHide}
            activityId={taskDetails.activityId}
            activityFolderId={taskDetails.activityFolderId}
          />
        </>
      )}
    </>
  );
}
