import React, { useState, useContext, useRef } from "react";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import useAuth from "../hooks/useAuth";
import { uploadFile } from "../services/apiFiles";
import { Toast } from "primereact/toast";
import { Sidebar } from "primereact/sidebar";
import { SelectedFolderContext } from "../contexts/SelectedFolderContext";
import { RefreshFolderContentContext } from "../contexts/RefreshFolderContentContext";
import { actionSuccess, dialogMessageList } from "../common/dialogMessages";

export default function BulkUploader() {
  const { selectedFolder } = useContext(SelectedFolderContext);
  const { setShouldRefreshContents } = useContext(RefreshFolderContentContext);
  const [visibleTop, setVisibleTop] = useState(false);
  const { getToken } = useAuth();

  const toast = useRef(null);
  const customBase64Uploader = async (event) => {
    const accessToken = getToken();

    for (let i = 0; i < event.files.length; i++) {
      const file = event.files[i];

      let formData = new FormData();
      formData.append("file", file);
      formData.append("folderId", selectedFolder.key);

      uploadFile(formData, accessToken)
        .then((response) => {
          delete event.files[i];
          toast.current.show(
            actionSuccess(dialogMessageList.FileUploadSuccessful)
          );
          return event.files.filter(Boolean);
        })
        .then((fileList) => {
          if (fileList.length === 0) {
            event.files = [];
            event.options.clear();
            setShouldRefreshContents(true);
            setVisibleTop(false);
          }
        })
        .catch((e) => {
          throw e;
        });
    }
  };

  const chooseOptions = {
    icon: "pi",
    label: "",
    iconOnly: false,
    className: "p-button",
  };

  return (
    <>
      <Toast ref={toast} />
      <Button
        label="Upload File(s)"
        className="w-12rem p-button p-button-primary mr-3"
        onClick={() => {
          setVisibleTop(true);
        }}
        icon="pi pi-cloud-upload"
        disabled={!selectedFolder.hasOwnProperty("key")}
      />
      <Sidebar
        position="top"
        visible={visibleTop}
        className="bg-blue-100 p-sidebar-top-halfheight"
        onHide={() => setVisibleTop(false)}
      >
        {visibleTop && (
          <FileUpload
            mode="advanced"
            name="uploadDocumentFile"
            accept=".pdf"
            customUpload
            disabled={!selectedFolder.hasOwnProperty("key")}
            chooseOptions={chooseOptions}
            chooseLabel="Select File(s)"
            uploadHandler={customBase64Uploader}
            multiple
            auto
            emptyTemplate={
              <p className="m-0">
                Upload automatically starts once file(s) are selected.
              </p>
            }
          />
        )}
      </Sidebar>
    </>
  );
}
