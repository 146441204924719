import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import useAuth from "../hooks/useAuth";
import { uploadFile, attachMainFiles } from "../services/apiFiles";
import { Toast } from "primereact/toast";
import { Sidebar } from "primereact/sidebar";
import { actionSuccess, dialogMessageList } from "../common/dialogMessages";
import { attachTaskFile } from "../services/apiTasks";
import { Checkbox } from "primereact/checkbox";

export default function TaskBulkUploader(props) {
  const [selectedFolderId, setSelectedFolderId] = useState(props.folderId);
  const [taskId, setTaskId] = useState(props.taskId);
  const [visibleTop, setVisibleTop] = useState(false);
  const [checked, setChecked] = useState(false);
  const { getToken } = useAuth();

  const toast = useRef(null);

  useEffect(() => {
    setSelectedFolderId(props.folderId);
    setTaskId(props.taskId);
  }, [props.folderId, props.taskId]);

  const customBase64Uploader = async (event) => {
    const accessToken = getToken();

    for (let i = 0; i < event.files.length; i++) {
      const file = event.files[i];

      let formData = new FormData();
      formData.append("file", file);
      formData.append("folderId", selectedFolderId);

      uploadFile(formData, accessToken)
        .then((response) => {
          if (checked) {
            attachMainFiles(
              parseInt(props.activityId),
              { fileIds: [response.id] },
              accessToken
            )
              .then((response) => {})
              .catch((e) => {
                throw e;
              });
          } else {
            attachTaskFile(
              parseInt(taskId),
              { fileIds: [response.id] },
              accessToken
            )
              .then((response) => {})
              .catch((e) => {
                throw e;
              });
          }

          delete event.files[i];
          toast.current.show(
            actionSuccess(dialogMessageList.FileUploadSuccessful)
          );
          return event.files.filter(Boolean);
        })
        .then((fileList) => {
          if (fileList.length === 0) {
            event.files = [];
            event.options.clear();
            setVisibleTop(false);
            window.location.reload(false);
          }
        })
        .catch((e) => {
          throw e;
        });
    }
  };

  const chooseOptions = {
    icon: "pi",
    label: "",
    iconOnly: false,
    className: "p-button",
  };
  const setCheckedd = (options) => {
    setChecked(options);
  };
  const headerTemplate = (options) => {
    const { chooseButton } = options;
    return (
      <>
        <div className="flex justify-content-between my-1 align-items-center">
          <div className="flex justify-content-start">{chooseButton}</div>
          {props.forWetSignatureDocuments && (
            <div className="flex justify-content-end">
              <Checkbox
                inputId="binary"
                checked={checked}
                onChange={(e) => setCheckedd(e.checked)}
                className="mr-2"
              />
              <label htmlFor="binary">Wet Signature Document(s)</label>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <Button
        label="Upload File(s)"
        className="w-12rem p-button p-button-primary mr-3"
        onClick={() => {
          setVisibleTop(true);
        }}
        icon="pi pi-cloud-upload"
        disabled={!selectedFolderId}
      />
      <Sidebar
        position="top"
        visible={visibleTop}
        className="bg-blue-100 p-sidebar-top-halfheight"
        onHide={() => setVisibleTop(false)}
      >
        {visibleTop && (
          <>
            <FileUpload
              mode="advanced"
              name="uploadDocumentFile"
              accept=".pdf"
              customUpload
              disabled={!selectedFolderId}
              chooseOptions={chooseOptions}
              chooseLabel="Select File(s)"
              uploadHandler={customBase64Uploader}
              headerTemplate={headerTemplate}
              multiple
              auto
              emptyTemplate={
                <p className="m-0">
                  Upload automatically starts once file(s) are selected.
                </p>
              }
            />
          </>
        )}
      </Sidebar>
    </>
  );
}
