const deleteDialog = (accept, reject, message) => {
  return {
    message: message,
    header: "Delete Confirmation",
    icon: "pi pi-info-circle",
    acceptClassName: "p-button-danger",
    accept,
    reject,
  };
};

const actionSuccess = (message) => {
  return {
    severity: "success",
    summary: "Confirmed",
    detail: message,
    life: 3000,
  };
};

const actionCancel = (message) => {
  return {
    severity: "warn",
    summary: "Canceled",
    detail: message,
    life: 3000,
  };
};

const dialogMessageList = {
  FileDeleteSuccessful: "File deleted successfully.",
  FileUploadSuccessful: "File uploaded successfully.",
  WorkflowDeleteSuccessful: "Workflow deleted successfully.",
  WorkflowDuplicateSuccessful: "Workflow duplicated successfully.",
  WorkflowSaveSuccessful: "Workflow saved successfully.",
  UserCanceled: "User canceled.",
  DocumentDeletePrompt: "Do you want to delete this document?",
  WorkflowDeletePrompt: "Do you want to delete this workflow?",
  ActivityCreateSuccessful: "Draft activity created successfully.",
  TaskCompletedSuccessful: "Task completed successfully.",
  ActivityStartSuccessful: "Activity started successfully.",
  OICUpdateSuccessful: "Final Approval OIC updated successfully.",
};

export { deleteDialog, actionSuccess, actionCancel, dialogMessageList };
